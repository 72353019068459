* {
  padding: 0;
  margin: 0;
  text-align: left;
  /* font-family: 'Nunito', sans-serif; */
  /* font-family: 'Figtree', sans-serif; */
  /* font-family: 'Zen Kaku Gothic New', sans-serif; */
  font-family: 'IBM Plex Sans KR', sans-serif;
  font-weight:300;
  font-size: 13px;
  color: black;
  text-decoration: none;
}

:root {
  --main-grey:#E1E0DD;
  --main-yellow:#EFD282;
  --main-blue:#C6D3E1;
  --main-green:#6E8F84;
  --main-red:#BB7778;
}

ul{
  list-style-type:none;
}

.fullHeight {
  height: 100vw;
}

#intro {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

#intro > div {
  background-color:var(--main-grey);
  background-image: url(../imgs/background.png);
  background-size:cover;
  background-position: 50% 50%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 8vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

#intro-name {
  font-size: 16px;
  width: 100%;
  margin-bottom: 3%;
}

#intro-description {
  font-size: 34px;
  font-weight: 100;
  line-height: 100%;
  width: 100%;
  max-width: 1200px;
  font-family: 'Nunito', sans-serif;

}

#intro-description a {
  font-family: 'Unica One', cursive;
  font-size: larger;
  color: var(--main-grey);
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

#intro-description a:hover {
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 5px 5px 8px  var(--main-yellow);
}

/* #intro-description a:first-child:hover {
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 5px 5px 8px  var(--main-yellow);
} */

/* #intro-description span:nth-child(2):hover {
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 5px 5px 8px  var(--main-yellow);
} */

#logo_initials, #logo_fullname, #whereamI  {
  position: absolute;
  left: 13vw;
  top: 13vw;
  padding-top: 12px;
}

#logo_initials img, #logo_fullname img {
  height: 32px;
}

#logo_fullname {
  display: none;
}

#arrow_down {
  position: absolute;
  bottom: 5%;
  height: 40px;
  overflow: hidden;
}

#arrow_down img {
  position: relative;
  bottom: 20px;
}


/* #arrow_left {
  width: 30px;
} */

#menu-icon, #menu-icon-close {
  position: fixed;
  top: 13vw;
  right: 13vw;
  z-index: 101;
}

#menu-icon-close-tetris {
  position: inherit;
  top: 13vw;
  right: 13vw;
  z-index: 101;
}

#menu-open {
  z-index: 100;
}

#menu-open a {
  font-size: 40px;
  margin: 0.5rem;
}

#menu-open a:hover {
  color: var(--main-yellow);
}

#menu-contactOptions {
  margin: auto;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
}

#menu-contactOptions a {
  margin: 5vw;
  margin-bottom: 20vh;
  padding: 0;
  text-align: center;
}

section {
  box-sizing: border-box;
  width: 100vw;
  padding: 14vh 13vw;
  padding-bottom: 4vh;
  margin: auto;
}

h1 {
  font-family: 'Nunito', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: var(--main-yellow)
}

/* .whareamI {
  text-transform: none;
  font-weight: 600;
  font-size: 32px;
  font-family: 'Unica One', cursive;
  position: absolute;
  left: 13vw;
  top: 13vw;
  padding-top: 12px;
} */

#whereamI {
  padding: 0;
  font-family: 'Unica One', cursive;
  font-size: 40px;
}

hr {
  width: 20%;
  border: 1px solid var(--main-yellow);
}

br {
  content: "";
  display: block !important;
  height: 0.6rem;
}

/* #principles-description {
  margin: 7vh 0;
} */

#principles-description > div  {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 350px;
  margin: auto;
  margin-top: 2vh;
} 



#principles-description > div > img {
  width: 50%;
  max-width: 200px;
}

#principles-description p {
  font-size: 12px;
}

button {
  margin: auto;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  text-decoration:none;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;  
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
}

.primary-button img {
  height: 30%;
  margin-left: 10px;
  filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(215deg) brightness(95%) contrast(105%);
}

button:hover {
  cursor: pointer; 
}

.primary-button {
  background-color: var(--main-yellow);
  border: none;
  color: white;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid var(--main-yellow);
  color: var(--main-yellow);

}

.invisible-button {
  border: none;
  background-color: transparent;
}

#menu-open {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#menu-open > div {

  border: 1px solid var(--main-yellow);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 8%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

#footer {
  border-top: 1px solid var(--main-yellow);
  margin: 20px;
  padding: 3vh 13vw;
  padding-bottom: 1vh;

}
#footer-actions {
  margin: 0 -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.contactOption {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0.5rem;
}

.contactOption img {
  width: 1rem;
  margin-right: 0.5rem;
}

#copyrightSignature {
  font-size: x-small;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#arrow_up {
  filter: invert(80%) sepia(29%) saturate(505%) hue-rotate(5deg) brightness(99%) contrast(94%);
}

html { 
  scroll-behavior: smooth; 
}

#projectCards {
  display: flex;
  flex-direction: column;
}

.projectCard {
  margin-top: 2rem;
  width: 100%;
}

.projectImage {
  width: 100%;
  height: 40vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
}

.projectImage img {
  width: 100%;
  object-fit: cover;
  background-color: lightgrey;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: var(--main-grey);
}

.projectImage:hover .overlay {
  opacity: 0.8;
}

.overlayText {
  width: 80%;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline var(--main-yellow);
  /* text-transform: uppercase; */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

#or {
  text-align: center;
  font-family:'Unica One', cursive;
  font-size: 40px;
  text-decoration:none;
  display:inline-block;
  color: var(--main-grey);
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}


h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 0.3rem 0;
}

.projectCard p {
  font-size: 12px;
  margin: 0 1rem;
}

.projectCard h3 {
  margin: 0.5rem 1rem;
}

#picture-and-story {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstPageElement {
  margin-top: 30px;
}


#picture-me {
  margin-bottom: 5vh;
}

#picture-me img {
  width: 100%;
  object-fit: cover;
  background-color: white;
  flex-basis: content;
}

#story-me p {
  margin-top: 2rem;
}

#about-page h2 {
  font-family: 'Unica One', cursive;
  font-size: 40px;
  color: var(--main-yellow)
}

#about-page h3 {
  font-size: 14px;
  font-weight: 600;
}

.features {
  padding: 2%;
  margin-top: 20px;
}

.listbox {
  border: 1px solid var(--main-yellow);
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.listbox ul{
  list-style-type:none;
  font-size: 12px;
  margin-bottom: 1rem;
}


#experience > div, #education > div {
  margin-top: 2rem;
}

#randomFact {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  position: relative;
  text-align: center;
  margin-top: 2rem;
}

#randomFactText {
  width: 50vw;
  height: 10vh;
  color: white;
  font-size: 3vh;
  font-weight: 600;
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -25vw;
  margin-top: -5vh;
}

#randomFactImage {
  width: 100%;
  height: 40vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

#randomFactImage img {
  width: 100%;
  object-fit: cover;
  background-color: black;
}

#randomFactButton {
  position: absolute;
  bottom: 10%;
  left: 50%;
  margin-left: -60px;
}

.filter-button-group {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.filter-button {
  margin: 0 8px 8px 0;
  height: 25px;
  font-size: 12px;
  border: 0;
  color: white;
}

.filter-button.uxdesign {
  background-color: var(--main-yellow);
}

.filter-button.industrialdesign {
  background-color: var(--main-red);
}

.filter-button.webdev {
  background-color: var(--main-green);
}

.disabled {
  opacity: 0.4;
  text-decoration: line-through;
}

.showAll {
  color: black;
  border: 1px solid;
  background-color: white;
}

.projectBody {
  display: flex;
  flex-direction: column;
}




#projectRumbleImage {
  width: 100%;
  border-radius: 10vw;
  overflow: hidden;
  height: 156vw;
  box-shadow: -5px 5px 16px 7px rgba(0,0,0,0.23);
}

#projectRumbleImage img {
  width: 100%;
}

#projectRumbleText {
  width: 100%;
}

.projectDetails h3 {
  margin-top: 20px;
}

.projectDetails h1 {
  margin-top: 20px;
}

#projectGiraffeImage {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

#projectGiraffeImage img {
  width: 100%;
}



.footnote {
  font-size: 10px;
  margin-top: 20px;
  text-align: center;
}

.work-repartition {
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.part-design{
  width: 20px;
  height: 10px;
  background-color: var(--main-yellow);
  border-radius: 5px 0 0 5px;
  margin-right: 2px;
  display: inline-block;
}

.part-webdev{
  width: 180px;
  height: 10px;
  background-color: var(--main-green);
  border-radius: 0 5px 5px 0;
  display: inline-block;
}

.booklet {
  box-shadow: -5px 5px 16px 7px rgba(0,0,0,0.23);
  display: flex;
  flex-direction: row;
}

.one-page {
  width: 50%;
  margin: auto;
}

.booklet img {
  width: 50%;
}

.one-page img{
  width: 100%;
}

#booklet-pagination {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginate-element {
  display: flex;
  margin: 10px;
}

#booklet-pagination img {
  width: 30px;
}

.process-step {
  border: 1px solid var(--main-yellow);
  padding: 10px;
  display: inline-block;

}

.highlight {
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
  text-decoration: underline var(--main-yellow) !important;
}

.goplay {
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
  display: none;
  background-color: none;
}

#tetris {
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1000;
}

#keyboard {
  width: 550px;
  margin-top: 40px;
}


@media screen and (min-width:481px) {

  #arrow_down {
    bottom: 10vh;
  }

  #principles-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
  }

  #principles-description > #interdisciplinary-collaboration {
    flex-direction: row-reverse;
  }

  .listbox {
    flex-direction: row;
    justify-content: space-around;;
  }

  .listbox li, .listbox h3 {
    text-align: center;
  }

  .projectBody {
    flex-direction: row;
  }

  #projectRumbleImage {
    margin: 0 5% 0 0;
    border-radius: 5vw;
    height: 74vw;
  }

  .firstPageElement {
    margin-top: 60px;
  }

  #intro-description {
    font-size: 40px;
  }

  #arrow_down {
    height: 60px;
    overflow: hidden;
  }
  
  #arrow_down img {
    position: relative;
    bottom: 0px;
  }
}

@media screen and (min-width: 768px) {

  #intro > div {
    padding: 12vw;
  }

  #logo_initials, #logo_fullname, #whereamI {
    left: 8vw;
    top: 8vw;
  }
  
  #arrow_down {
    bottom: 13vh;
  }
  
  #menu-icon, #menu-icon-close, #menu-icon-close-tetris {
    top: 8vw;
    right: 8vw;
  }

  #picture-and-story {
    flex-direction: row;
    justify-content: flex-start;
    align-items:flex-start;
    flex-basis: 0;
  }

  .firstPageElement {
    margin-top: 80px;
  }

  #picture-me {
    margin: 0 5% 0 0;
    max-width: 500px;
  }

  #projectRumbleImage {
    background-color: black;
    max-width: 260px;
    border-radius: 36px;
    max-height: 550px;
  }

  .goplay {
    display: block;
    background-color: none;
    position: absolute;
    right: 5%;
    bottom: 15%;
    font-size: 40px;
    font-weight: 200;
    height: auto;
    width: auto;
    padding: 10px 60px;
    transform: rotate(-0.05turn);
    border-radius: 50px;
    border: none;
  }

  .goplay:hover {
    text-shadow: 5px 5px 8px  var(--main-yellow);
  }

  .overlayText {
    font-size: 24px;
  }
  
  #or {
    font-size: 80px;
  }
}

@media screen and (min-width:1200px) {
  
  #projectCards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projectCard {
    width: 49%;
  }
  
  .projectImage {
    height: 20vw;
  }
  
  #logo_fullname {
    display: block;
  }
  
  #logo_initials {
    display: none;
  }
  

  .firstPageElement {
    margin-top: 100px;
  }

  #intro-description {
    font-size: 60px;
  }
  
  #projectRumbleImage {
    max-width: 260px;
    max-height: 550px;
  }
}
@media screen and (min-width:1600px) {

  section {
    padding: 8vh 0;
    margin: auto;
    margin-top: 8vh;
    width: 1200px;
  }


  #footer-actions  {
    width: 1200px;
    margin: auto;
  }

  .projectImage {
    height: 320px;
  }

  .firstPageElement {
    margin-top: 120px;
  }

  #intro-description {
    font-size: 60px;
  }


}

.animate__animated.animate__fadeInTopRight {
  --animate-duration: 0.2s;
}

